import React, { useMemo, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import useFetch from "../../../hooks/useFetch"
import { Link } from "gatsby"
import { Col, Row } from "reactstrap"
import { useAuth } from "../../../auth/useAuth"

const SpaceOperators = () => {
  const [reFetch, setReFetch] = useState(0)

  const { progress, data } = useFetch(
    "worksimply-approved-cafes/view",
    "post",
    {},
    reFetch
  )

  const { makeRequest } = useAuth() || {}

  const [search, setSearch] = useState("")

  const deleteCafe = async (e, id) => {
    e.preventDefault()

    if (!window.confirm("Delete cafe?")) {
      return
    }

    await makeRequest("worksimply-approved-cafes/delete", {
      id,
    })

    setReFetch(reFetch + 1)
  }

  return (
    <>
      <SEO title="Worksimply Approved Cafes" />
      <Layout title={"Worksimply Approved Cafes"} progress={progress}>
        <Row>
          <Col xl={6}>
            <input
              type="text"
              placeholder={"Type 2 or more letters to search by name"}
              className="form-control mb-4"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col xl={6} className={"text-right"}>
            <Link
              to={`/admin/approved-cafes/add`}
              className={"btn btn-success btn-sm"}
            >
              Add
            </Link>
          </Col>

          <Col xl={12}>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.list?.length === 0 && (
                  <tr>
                    <td colSpan={3} className={"text-center"}>
                      No cafes found.
                    </td>
                  </tr>
                )}
                {data?.list
                  ?.filter((cafe) => {
                    if (!search || search?.length < 2) return true
                    return (
                      cafe?.name?.toLowerCase()?.indexOf(search.toLowerCase()) >
                      -1
                    )
                  })
                  ?.map((cafe) => (
                    <tr key={cafe.id}>
                      <td>{cafe.name}</td>
                      <td>{cafe.address}</td>
                      <td className={"text-right"}>
                        <Link
                          to={`/admin/approved-cafes/view/${cafe.id}`}
                          className={"text-success"}
                        >
                          Edit
                        </Link>{" "}
                        |{" "}
                        <a
                          href={"#"}
                          className={"text-danger"}
                          onClick={(e) => {
                            deleteCafe(e, cafe.id).then(() => null)
                          }}
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default SpaceOperators
